import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

declare let FB: any;

@Injectable()
export class SocialService {
    constructor() {
        (window as any).fbAsyncInit = () => {
            FB.init({
                appId: environment.smartadmin.facebook.appId,
                cookie: true,
                xfbml: true,
                version: 'v3.2'
            });
            FB.AppEvents.logPageView();
        };
        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));    
    }
    
    connectFB(): Promise<any> {
        return new Promise((resolve, reject) => {
            function getFBData(response) {
                if (response.status === 'connected') {
                    FB.api('/me?fields=name,first_name,last_name,email&scope=email', userInfo => {
                        resolve({ response: response, userInfo: userInfo });
                    });
                } else {
                    reject();
                }
            }
            FB.getLoginStatus(response => {
                /*FB.logout(function(response){ console.log(response); });*/
                if (response.status === 'connected') {
                    getFBData(response);
                } else {
                    FB.login(response => {
                        getFBData(response);
                    }, { scope: 'public_profile,email' });
                }
            });
        });
    }
}
